"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";
import Layout from "../components/Layout";
import { SessionProvider } from "../components/contexts/SessionProvider";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    captureException(error);
  }, [error]);

  return (
    <SessionProvider>
      <Layout>
        <header className="flex flex-col gap-y-3 mx-10 lg:mx-20 xl:mx-32 my-10 border-l-8 border-blue-500 pl-5">
          <h1 className="text-2xl md:text-3xl text-blue-500">
            Something went wrong
          </h1>
        </header>
        <section className="px-10 lg:px-20 xl:px-32 py-10">
          We have been notified and will look into the issue. Meanwhile, you can{" "}
          <button
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
            className="text-blue-500 hover:text-blue-700 underline cursor-pointer"
          >
            try again
          </button>
          .
        </section>
      </Layout>
    </SessionProvider>
  );
}
