import Link from "next/link";
import Image from "next/image";
import { ReactNode } from "react";
import { SessionMenu } from "./SessionMenu";

import ELifeLogo from "../../public/partners/elife.svg";
import CosLogo from "../../public/partners/cos.png";
import ScieloLogo from "../../public/partners/scielo.svg";
import Ngi0Logo from "../../public/partners/NGI0.png";
import NlnetLogo from "../../public/partners/nlnet.png";

const Layout = (props: { children: ReactNode; }) => {
  return (
    <>
      <div className="flex flex-col h-full">
        <header className="flex justify-between items-center gap-10 px-10 lg:px-20 xl:px-32 py-5 bg-blue-50 text-blue-900">
          <Link href="/" className="text-2xl font-bold hover:text-blue-500">
            Plaudit
          </Link>
          <div><SessionMenu/></div>
        </header>
        <main className="flex-grow">{props.children}</main>
        <footer className="bg-slate-50 flex flex-wrap px-10 lg:px-20 xl:px-32 py-10 gap-20">
          <ul className="flex flex-col gap-2 text-lg grow">
            <li>
              <Link
                href="/"
                title="Plaudit homepage"
                className="text-2xl hover:underline"
              >
                Plaudit
              </Link>
            </li>
            <li>
              <Link href="/integration" className="hover:underline">
                Integrate
              </Link>
            </li>
          </ul>
          <div className="">
            <h2 className="text-xl">Partners</h2>
            <ul className="flex flex-wrap items-center gap-5 py-5">
              <li>
                <a href="https://elifesciences.org/labs" className="">
                  <Image
                    src={ELifeLogo}
                    alt="eLife Innovation"
                    className="h-16 w-auto pb-1 border-b-4 border-solid border-transparent hover:border-blue-400"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://cos.io/"
                  className="border-b-4 border-solid border-transparent hover:border-blue-400"
                >
                  <Image
                    src={CosLogo}
                    alt="Center for Open Science"
                    className="h-16 w-auto pb-1 border-b-4 border-solid border-transparent hover:border-blue-400"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://scielo.org/ "
                  className="border-b-4 border-solid border-transparent hover:border-blue-400"
                >
                  <Image
                    src={ScieloLogo}
                    alt="SciELO"
                    className="h-16 w-auto pb-1 border-b-4 border-solid border-transparent hover:border-blue-400"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.ngi.eu/"
                  title="The European Commission's Next Generation Internet programma"
                  className="border-b-4 border-solid border-transparent hover:border-blue-400"
                >
                  <Image
                    src={Ngi0Logo}
                    alt="NGI Zero"
                    className="h-16 w-auto pb-1 border-b-4 border-solid border-transparent hover:border-blue-400"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://nlnet.nl/NGI/"
                  title="NLNet's NGI Zero consortium"
                  className="border-b-4 border-solid border-transparent hover:border-blue-400"
                >
                  <Image
                    src={NlnetLogo}
                    alt="NLNet"
                    className="h-16 w-auto pb-1 border-b-4 border-solid border-transparent hover:border-blue-400"
                  />
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
