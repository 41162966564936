"use client";

import { signOut, useSession } from "next-auth/react";
import Link from "next/link";

export const SessionMenu = () => {
  const session = useSession();

  return (
    <>
      {session.status === "authenticated"
        ? <span className="flex items-center gap-5 md:gap-10">
          <Link
            href={`/endorser/${session.data.user.id}/`}
            className="hover:text-blue-500"
          >Profile</Link>
          <button onClick={() => signOut()} className="hover:text-blue-500">Sign out</button>
        </span>
        : null
      }
    </>
  );
};
